<template>
  <div class="section-wrapper">
    <b-breadcrumb>
      <b-breadcrumb-item to="/dashboard">
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
        Dashboard
      </b-breadcrumb-item>
      <b-breadcrumb-item to="/email-templates">Email Templates</b-breadcrumb-item>
      <b-breadcrumb-item active>{{ form.id ? 'Update' : 'Add New' }} Email Template</b-breadcrumb-item>
    </b-breadcrumb>
    <b-card>
      <b-card-title>
        <b-row>
          <b-col>
            <h4 class="card-title mb-0 pl-0">Email Template</h4>
          </b-col>
          <b-col class="text-right">
            <b-button size="sm" variant="info" @click="previousRouter()"><i class="ri-arrow-left-line"></i>Back</b-button>
          </b-col>
        </b-row>
      </b-card-title>
      <b-card-text>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(submitData)" @reset.prevent="reset" autocomplete="off">
            <b-row>
              <b-col lg="12" md="12" sm="12" xs="12">
                <ValidationProvider name="Template Name" vid="template_name" rules="required" v-slot="{ errors }">
                  <b-form-group
                    label-cols-sm="12"
                    label-for="template_name"
                  >
                  <template v-slot:label>
                    Template Name <span class="text-danger">*</span>
                  </template>
                    <b-form-input
                      id="template_name"
                      v-model="form.template_name"
                      placeholder="Enter Template Name"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col v-if="!form.id" lg="12" md="12" sm="12" xs="12">
                <ValidationProvider name="Email Code" vid="email_code" rules="required" v-slot="{ errors }">
                  <b-form-group
                    label-cols-sm="12"
                    label-for="email_code"
                  >
                  <template v-slot:label>
                    Email Code <span class="text-danger">*</span>
                  </template>
                    <b-form-input
                      id="email_code"
                      v-model="form.email_code"
                      placeholder="Enter Email Code"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="12" md="12" sm="12" xs="12">
                <ValidationProvider name="Email Subject" vid="subject" rules="required" v-slot="{ errors }">
                  <b-form-group
                    label-cols-sm="12"
                    label-for="subject"
                  >
                  <template v-slot:label>
                    Email Subject <span class="text-danger">*</span>
                  </template>
                    <b-form-input
                      id="subject"
                      v-model="form.subject"
                      placeholder="Enter Email Subject"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="12" md="12" sm="12" xs="12">
                <ValidationProvider name="Email Header" vid="header" rules="required" v-slot="{ errors }">
                  <b-form-group
                    label-cols-sm="12"
                    label-for="header"
                  >
                  <template v-slot:label>
                    Email Header <span class="text-danger">*</span>
                  </template>
                    <b-form-input
                      id="header"
                      v-model="form.header"
                      placeholder="Enter Email Header"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="12" md="12" sm="12" xs="12">
                <ValidationProvider name="Email Body" vid="email_body" rules="required" v-slot="{ errors }">
                  <b-form-group label-cols-sm="12" label-for="email_body">
                    <template v-slot:label>
                      Email Body
                    </template>
                    <vue2-tinymce-editor
                      id="email_body"
                      :width:="500" :height="300"
                      v-model="form.email_body"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :options="options"
                      >
                    </vue2-tinymce-editor>
                    <div class="d-block invalid-feedback">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <div class="row mt-3">
              <div class="col-sm-3"></div>
              <b-col class="text-right">
                <b-button type="submit" variant="success" style="min-width: 140px;" class="mr-2 btn-sm">{{ form.id ? 'Update' : 'Save' }}</b-button>
              </b-col>
            </div>
          </b-form>
        </ValidationObserver>
      </b-card-text>
    </b-card>
  </div>
</template>
<script>
import RestApi, { apiBaseURL } from '@/config'
import { Vue2TinymceEditor } from 'vue2-tinymce-editor'
import axios from 'axios'

export default {
  name: 'AddOrUpdateForm',
  components: {
    Vue2TinymceEditor
  },
  data () {
    return {
      form: {
        id: '',
        template_name: '',
        email_code: '',
        subject: '',
        header: '',
        email_body: ''
      },
      errors: [],
      valid: null,
      options: {
        table_column_resizing: 'resizetable',
        images_upload_handler: this.image_upload_handler
      }
    }
  },
  created () {
    this.form.id = this.hash_id(this.$route.params.id, false)[0]
  },
  mounted () {
    if (this.hash_id(this.$route.params.id, false)[0]) {
      this.getEmailTemplateById(this.form.id)
    }
  },
  computed: {
  },
  methods: {
    image_upload_handler: async function (blobInfo, success, failure) {
      console.log('blobInfo', blobInfo)
      console.log('success', success)
      console.log('failure', failure)

      const formData = new FormData()
      formData.append('photo', blobInfo.blob(), blobInfo.filename())
      formData.append('id', this.form.id)

      const accessToken = localStorage.getItem('access_token')

      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          accessMenuName: window.location.href,
          'Content-Type': 'multipart/form-data'
        }
      }

      const result = await axios.post(`${apiBaseURL}admin/ajax/upload_email_template_photo`, formData, axiosConfig)
      console.log('result', result)
      if (result.status === 200 && result.data.success === true) {
        success(result.data.location)
      } else {
        failure(result.data.message)
      }
    },
    async submitData () {
      this.loading = true
      let result = ''
      var formData = new FormData()
      Object.keys(this.form).map(key => {
        formData.append(key, this.form[key])
      })
      if (this.form.id) {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/update_email_template_data', formData)
      } else {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/store_email_template_data', formData)
      }
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: result.message
        })
        this.$router.push('/email-templates')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    previousRouter () {
      this.$router.push('/email-templates')
    },
    goToComp () {
      this.$router.push('/email-templates')
    },
    async getEmailTemplateById (id) {
      const params = Object.assign({}, { id })
      const result = await RestApi.getData(apiBaseURL, 'admin/ajax/get_email_template_by_id', params)
      if (result.success) {
        if (result.data) {
          this.form = result.data
        }
      }
    }
  }
}
</script>
<style>
</style>
